<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>资讯详情</span>
      </div>
    </div>
    <div class="ingds">
      <iframe width="100%" :height="vdaH" :src="zixunUrl"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zixunUrl: "",
      vdaH: "",
    };
  },
  created() {
    this.getHeight();
    let url = this.$route.query.viewUrl;
    console.log(url);
    this.zixunUrl = url;
  },
  methods: {
    getHeight() {
      let h =
        document.documentElement.clientHeight || document.body.clientHeight;
      console.log(h);
      this.vdaH = h - 40 + "px";
    },

    goback() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.ingds {
  width: 100%;
}
</style>